// App.js
import React, { useEffect } from 'react';
import VideoBackground from './components/VideoBackground';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import "@fontsource/montserrat";
import Aboutus from './about_us/Aboutus';


const App = () => {
  const dynamicVideoLink = "videos/Virtu Prep Landing 1 Vid.mp4";
  const pageName = "mainPage"
  const buttonText = "About Virtu Prep"

  useEffect(() => {
    window.addEventListener('resize', adjustHeightWidth);
    adjustHeightWidth(); // Call the function initially

    return () => {
      window.removeEventListener('resize', adjustHeightWidth);
    };
  }, []);

  const adjustHeightWidth = () => {
    const viewportHeight = window.innerHeight; 
    const viewportWidth = window.innerWidth; 

    const setElementSize = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.height = `${viewportHeight}px`;
        element.style.width = `${viewportWidth}px`;
      }
    };
  
    setElementSize("mainPage");
  } 
  return (
    <Router>
      <div className='mainSectionDiv'>
        <Routes>     
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/" element={<>
        <Navbar />
            {/* Only include components for the home route */}
            <VideoBackground
              videoLink={dynamicVideoLink}
              buttonText={buttonText}
              pageName={pageName}
            />           
          </>} />
        </Routes>
      </div>
    </Router>
    
  );
};

export default App;
