// VideoBackground.js
import React from 'react';
import '../global.css';
import './videobackground.css'
import { useState, useRef, useEffect } from 'react';

const VideoBackground = ({ videoLink, buttonText, pageName })  => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);
  
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = 0.5;
    }
  }, []); 

  const handleOnClick = (address) => {
    switch (address) {
      case 'measureLab':
        window.open('http://learningmeasurementlab.com');
        break;
      case 'softskills':
        window.open('https://softskills.school');
        break;
      case 'rethinkschool':
        window.open('http://rethink.school');
        break;
      case 'careerPrep':
        window.open('http://careerprep.school');
        break;
      case 'neoTest':
        window.open('http://neotestprep.com');
        break;
        case 'healthyBrain':
        window.open('http://healthybrain.Academy');
        break;
        case 'learnWithoutGate':
        window.open('http://learningwithoutgatekeepers.com');
        break;
      case 'realMerit':
        window.open('http://realmerit.academy');
        break;
      default:
    }
  };

  const handleJoinClick = () => {
    const destinationUrl = buttonText === "Home" ? "/" : "/about-us";
    window.location.href = destinationUrl;
  };
    // Function to adjust button widths
    const adjustButtonWidths = () => {
      const buttons = document.querySelectorAll('.product-btn');

      const learningMeasurementButton = document.getElementById('learningMeasurementBtn');

      if (learningMeasurementButton) {
  
        const width = learningMeasurementButton.offsetWidth;
        buttons.forEach(button => {
          button.style.width = `${width}px`;
        });
        learningMeasurementButton.style.width = `unset`;
      }

    };

    useEffect(() => {
      setTimeout(adjustButtonWidths, 100);

    });
    // Optional: Re-adjust if the window is resized
    window.addEventListener('resize', adjustButtonWidths);

   
  return (
    <div className="video-background" id="mainPage">
      <video ref={videoRef} autoPlay loop muted playsInline>
        <source src={videoLink} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay">

    </div >
    <div className='vp-container video-bg-1 z-index-9 mb-auto'>
    <div className="video-text justify-content-center text-yellow heading-left gap-3 gap-md-5 d-flex">
    

        <div class="mainPageContainer">
        {pageName === "mainPage" ? (
       <div className="container-fluid main-content position-relative">
        
        <div className='d-flex flex-column justify-content-around align-items-center h-50  gap-3 mb-auto'>
          <div className='rethinkschool banner-info w-100 d-flex flex-row gap-3 align-center justify-content-center'>
            <div className='flex-grow-1 d-lg-block d-none text-end text-container'><span className="ffe6c8 montser-LightItalic ft-1-3rem">Helping strengthen your existing school with:</span></div>
            <div className='flex-grow-1 d-lg-block btn-container-div text-center text-md-start'><button className='btn btn-size w-100 product-btn rethinkschool-btn col-ffe6c8 montser-lrg'
            onClick={() => handleOnClick('rethinkschool')}  >
                Rethink School
            </button>
          </div>
          </div>
          <div className='softskills banner-info w-100 d-flex flex-row gap-3 align-center justify-content-center'>
            <div className="flex-grow-1 d-lg-block d-none text-end text-container">
              <span className="ffe6c8 montser-LightItalic ft-1-3rem text-end">Helping you develop your soft skills with:</span>
            </div>
            <div className="btn-container-div flex-grow-1 d-lg-block btn-container-div text-center text-md-start"><button className='btn btn-size w-100 product-btn softskills-btn col-ffe6c8 montser-lrg'
              onClick={() => handleOnClick('softskills')} >Soft Skills</button>
            </div>
          </div>
          <div className='neoPrep banner-info w-100 d-flex flex-row gap-3 align-center justify-content-center'>
            <div className="flex-grow-1 d-lg-block d-none text-end text-container">
              <span className="ffe6c8 montser-LightItalic ft-1-3rem text-end"> Helping you ace your next exam with:</span>
            </div>
            <div className="flex-grow-1 d-lg-block btn-container-div text-center text-md-start">
              <button className='btn btn-size w-100 product-btn neoTest-btn col-ffe6c8 montser-lrg'
              onClick={() => handleOnClick('neoTest')}  >
                  Neo Test Prep 
              </button>
            </div>
          </div>
          <div className='careerPrep banner-info w-100 d-flex flex-row gap-3 align-center justify-content-center'>
            <div className="flex-grow-1 d-lg-block d-none text-end text-container">
              <span className="ffe6c8 montser-LightItalic ft-1-3rem text-end">Prove you have the skills for the market with:</span>
            </div>
            <div className="flex-grow-1 d-lg-block btn-container-div text-center text-md-start">
              <button className='btn btn-size w-100 product-btn careerPrep-btn col-ffe6c8 montser-lrg'
            onClick={() => handleOnClick('careerPrep')} > Career Prep</button>
            </div>
          </div>
          <div className='realMerit banner-info w-100 d-flex flex-row gap-3 align-center justify-content-center'>
            <div className="flex-grow-1 d-lg-block d-none text-end text-container">
              <span className="ffe6c8 montser-LightItalic ft-1-3rem text-end">Your alternative school to build your real merit is:</span>
            </div>
            <div className="flex-grow-1 d-lg-block btn-container-div text-center text-md-start">
              <button className='btn btn-size w-100 product-btn realMerit-btn col-ffe6c8 montser-lrg'
            onClick={() => handleOnClick('realMerit')} >Real Merit Academy</button>
            </div>
          </div>
          <div className='healthyBrain banner-info w-100 d-flex flex-row gap-3 align-center justify-content-center'>
            <div className="flex-grow-1 d-lg-block d-none text-end text-container">
              <span className="d-none flex-grow-1 d-lg-block ffe6c8 montser-LightItalic ft-1-3rem text-end">Your brain training academy for a healthy brain is:</span></div>
            <div className="flex-grow-1 d-lg-block btn-container-div text-center text-md-start"><button className='btn btn-size w-100 product-btn healthyBrain-btn col-ffe6c8 montser-lrg'
            onClick={() => handleOnClick('healthyBrain')} >Healthy Brain Academy</button></div>
          </div>
          <div  className='measureLab banner-info w-100  d-flex flex-row gap-3 align-center justify-content-center'>
            <div className="flex-grow-1 d-lg-block d-none text-end text-container">
              <span className="ffe6c8 montser-LightItalic ft-1-3rem text-end">The future of academic assessments is here with: </span>
            </div>
            <div className="flex-grow-1 d-lg-block btn-container-div text-center text-md-start"><button  id="learningMeasurementBtn" className='btn btn-size w-100 product-btn measureLab-btn col-ffe6c8 montser-lrg'
             onClick={() => handleOnClick('measureLab')}              
              > Learning Measurement Lab</button>
            </div>
          </div>
        </div>
   </div>
        ) : (  <h1 className='custom-large-heading light-col common-textshadow mobile-heading montser-lrg-LightItalic'><br></br> <br></br> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  </h1>
          )}
        </div>     

      
       </div>
      
      
    </div>
    <div className='vp-container d-flex flex-row gap-4 mb-3 pos-rel z-index-9'>
      <div className="flex-grow-1 d-flex flex-column gap-3 gap-md-4">
        {pageName === "mainPage" ? (
            <h1 className='text-sm light-col common-textshadow mobile-heading'>
              Your elevated learning environment
            </h1>
          ) : (
            <h1 className='custom-large-heading light-col common-textshadow mobile-heading'>
              About Us - Virtu Prep
            </h1>
          )}
  <div className='about'>
  <div className="row align-items-end gap-3">
    <div className="d-flex mb-2 mb-md-0">
      <div className="flex-1"><button type="button" className="outline-lightgreen py-0 rounded-top rounded-0 btn btn-outline-light montser-mdm-2 about-btn" onClick={handleJoinClick}>
        About Us
      </button></div>
      <div class="d-flex"><div class="video-controls">
      <button onClick={togglePlayPause} className='rounded-top pause-btn-div rounded-0 video-button montser-mdm-2'>
          {isPlaying ? (
            <>
              <i className="demo-icon icon-pause video-btn-icon-size"></i> 
            </>
          ) : (
            <>
              <i className="demo-icon icon-play video-btn-icon-size"></i> 
            </>
          )}
        </button>
        </div></div>
     
    </div>
    <div className="col-md-6 col-12 mb-2 ">
      <span className='col-ffe6c8 montser-mdm-2'>
        Virtu Prep © 2015-2024
      </span>
       {/* <button onClick={togglePlayPause} className='rounded-top pause-btn-div rounded-0 video-button montser-mdm-2'>
          {isPlaying ? (
            <>
              <i className="demo-icon icon-pause video-btn-icon-size"></i> 
            </>
          ) : (
            <>
              <i className="demo-icon icon-play video-btn-icon-size"></i> 
            </>
          )}
        </button> */}
    </div>
    {/* <div className="col-md-3 d-flex col-12 col-sm-6">
      <div className="video-controls">
      
      </div>
    </div> */}
  </div>
</div>


          
     </div>
    </div>
    </div>
  );
};

export default VideoBackground;
