// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutSectionActive  .bg-dark {
    background: #1c2933 !important;
    color: #1c2933;
  }
  
  .aboutSectionActive .text-yellow {
    color: #ffe6c8  !important;
  } 
  
  .aboutSectionActive .bg-dark-footer{
    background: transparent !important;
    color: #ffe6c8  !important;
  }

  .bg-body-about {
    background-color: #7b3038;
    color:#ffe6c8;
  }
  .col-ffe6c8{
    color:#ffe6c8
  }`, "",{"version":3,"sources":["webpack://./src/about_us/aboutus.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,cAAc;EAChB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kCAAkC;IAClC,0BAA0B;EAC5B;;EAEA;IACE,yBAAyB;IACzB,aAAa;EACf;EACA;IACE;EACF","sourcesContent":[".aboutSectionActive  .bg-dark {\n    background: #1c2933 !important;\n    color: #1c2933;\n  }\n  \n  .aboutSectionActive .text-yellow {\n    color: #ffe6c8  !important;\n  } \n  \n  .aboutSectionActive .bg-dark-footer{\n    background: transparent !important;\n    color: #ffe6c8  !important;\n  }\n\n  .bg-body-about {\n    background-color: #7b3038;\n    color:#ffe6c8;\n  }\n  .col-ffe6c8{\n    color:#ffe6c8\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
