import React, { useState, useEffect } from 'react';
import '../global.css';
import '../fontello.css';

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);


  const [activeLogo, setActiveLogo] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    const handleDocumentClick = (e) => {
      if (!isNavbarCollapsed && !e.target.closest('.navbar')) {
        setIsNavbarCollapsed(true);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {
      const activeSection = document.querySelector(".mainSectionDiv");
      const classes = activeSection.classList;
      const secondClass = classes.length >= 2 ? classes[1] : classes[0];
      switch (secondClass) {
        case "studentSectionActive":
          setActiveLogo("logos/VP_Logo_lightYellow.svg");
          break;

        case "parentSectionActive":
          setActiveLogo("logos/VP_Logo_darkBlue.svg");
          break;

        case "corporationSectionActive":
          setActiveLogo("logos/VP_Logo_navyBlue.svg");
          break;

        case "administratorsSectionActive":
          setActiveLogo("logos/VP_Logo_brown.svg");
          break;

        case "teacherSectionActive":
          setActiveLogo("logos/VP_Logo_lightYellow.svg");
          break;

        case "tutorsSectionActive":
          setActiveLogo("logos/logo.svg");
          break;
// -----------------------------------------------
        case "enhancedAiSectionActive":
          setActiveLogo("/logos/VP_Logo_lightYellow.svg");
          break;

        case "neurofeedbackSectionActive":
        setActiveLogo("/logos/VP_Logo_darkBlue.svg");
        break;

        case "specialNeedsSectionActive":
          setActiveLogo("/logos/VP_Logo_navyBlue.svg");
          break;

        case "cultureSectionActive":
          setActiveLogo("/logos/VP_Logo_brown.svg");
          break;


        case "managementsolutionsSectionActive":
          setActiveLogo("/logos/logo.svg");
          break;  

        case "braintrainingSectionActive":
            setActiveLogo("/logos/logo.svg");
            break; 
// -----------------------------------------------
        case "tutorSectionActive":
          setActiveLogo("/logos/VP_Logo_darkBlue.svg");
          break;

        case "neuroSectionActive":
          setActiveLogo("/logos/VP_Logo_darkBlue.svg");
          break;

        case "educationSectionActive":
          setActiveLogo("/logos/VP_Logo_navyBlue.svg ");
          break;

        case "kaleidoscopeSectionActive":
          setActiveLogo("/logos/VP_Logo_brown.svg");
          break;

        case "matrixSectionActive":
          setActiveLogo("/logos/VP_Logo_lightYellow.svg");
          break;

        case "repetitionSectionActive":
          setActiveLogo("/logos/VP_Logo_lightYellow.svg");
          break;

        case "shadowsSectionActive":
          setActiveLogo("/logos/VP_Logo_brown.svg");
          break;

        case "blogdashSectionActive":
          setActiveLogo("/logos/logo.svg");
          break;

        case "researchSectionActive":
          setActiveLogo("/logos/logo.svg");
          break;

          case "aboutSectionActive":
            setActiveLogo("/logos/logo.svg");
            break;
            

        default:
          setActiveLogo("logos/logo.svg");
          break;
      }
    }, 50);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavbarCollapsed]);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? 'bg-dark' : 'bg-1c2933-90opa'}`;

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownToggle1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const baseUrl = "https://platform."

  const handleJoinClick = () => {
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const domain = urlObject.hostname;
    window.open(baseUrl+ domain, '_blank');
  };

  const handleLoginClick = () => {
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const domain = urlObject.hostname;
    window.open(baseUrl+ domain, '_blank');
  };

  return (
    <nav className={`navbar navbar-expand-lg position-fixed   ht-10vh ${scrolling ? 'bg-dark' : ''}`}>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
        <div className='logo-container d-inline-flex align-items-center gap-2 maxw-265 w-100 me-xl-5 me-0'>
              <h1 className="siteLogo">
              <a href="#">
                <img src={activeLogo} />
              </a>
              </h1>
              <a className="navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href="/">
          <span>Virtu Prep</span>
        </a>
          </div>
        <button
          className="navbar-toggler border-0 text-white"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavbarCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}
        >
        </button>
      </div>
    </nav>
  );
};

export default Navbar;