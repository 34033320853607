// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../fontello.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';

import Navbar from '../components/Navbar';


const Aboutus = () => {
    const dynamicVideoLink = "videos/Sequence 01_2.mp4";
    const pageName = "aboutPage"
    const buttonText = "Home"

    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("aboutSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <VideoBackground
                videoLink={dynamicVideoLink}
                buttonText={buttonText}
                pageName={pageName}
            />
            <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <div className='content-margin '>
                       
                       
                       
                        <div className='mt-2 pt-3'>
                        <heding className='montser-lrg-bold align-items-center py-4'>A New Era in Education: Personalized, Data-Driven Learning: </heding>

                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Welcome to Virtu Prep, a pioneering force in education where the combined powers of AI, neuroscience, and in-depth learning insights converge. Our educational platform transcends traditional teaching methods, reimagining the delivery and perception of education in a way that's as innovative as it is effective.
                        </p>
                       
           
                        <heding className='montser-lrg-bold align-items-center py-4'>Our Vision: Tailored Education Powered by Advanced Data</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        In a world where education often takes a one-size-fits-all approach, Virtu Prep stands apart. We believe education should be as unique as the individual learner. Our philosophy centers on recognizing and nurturing each student's distinct learning path. We understand that true educational effectiveness comes from focusing on each student's specific needs, harnessing their attention, and guiding them along their unique learning journey.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Our platform is a testament to this belief. Tailored to adapt to each student's strengths and areas for growth, it employs AI-driven analysis to continually optimize their learning experience. This personalized approach is further enhanced by interactive and engaging gamification elements, making learning not just productive but also enjoyable.
                        </p>
                        

                        <heding className='montser-lrg-bold align-items-center py-4'> Empowering Learners and Educators:</heding>

                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Our platform is designed to ensure that every student, regardless of background, has access to quality education. 
                        </p>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Central to our approach is a deep commitment to insightful, data-driven measurements. Virtu Prep's advanced analytics are adept at capturing actionable data points – 10,000 times more than traditional educational methods. This data offers comprehensive insights into each student's educational progress, capturing nuances in engagement, learning speed, and cognitive responses. Our platform utilizes this data to craft learning experiences that are dynamically adapted to each student's evolving needs.
                        </p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        We also recognize the vital role of parents and guardians in the educational process. Our platform includes tools for parental involvement, enabling them to stay connected and actively participate in their child's education.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap'>
                        For educators and administrators, this wealth of data opens up new possibilities for tailored teaching strategies, allowing them to become architects of personalized learning experiences. Virtu Prep also plays an active role in professional development, offering educators and administrators workshops, webinars, and training to keep them abreast of the latest in teaching strategies and technologies.
                        </p>
                       


                        <heding className='montser-lrg-bold align-items-center py-4'>A Holistic Educational Experience: Inclusivity and Accessibility:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Our inclusive platform addresses a spectrum of educational needs. We cater to students with learning challenges, homeschoolers, and those in disadvantaged and underserved communities with equal dedication. Our tools – ranging from gamified learning environments to interactive whiteboards and virtual classrooms – are designed to engage, inspire, and educate in a way that traditional classrooms cannot.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        We understand the role of parents and guardians in the educational journey. Our platform includes tools for parental involvement, offering progress reports and performance dashboards to keep them informed and engaged.
                        </p>
                        
                    

                        <heding className='montser-lrg-bold align-items-center py-4'>Beyond Academics: Fostering Life Skills and Personal Growth: </heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Virtu Prep’s mission extends beyond academic success. We place a strong emphasis on developing essential life skills such as problem-solving, resilience, and self-directed learning. Our aim is to equip learners not just for academic achievements but for real-world challenges, fostering personal and professional growth.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Security, privacy, and continuous innovation are foundational to our operations. We're dedicated to regularly enhancing our platform, informed by user feedback, research, and the latest technological advancements, all while ensuring the utmost data security and privacy.
                        </p>
                       

                        <heding className='montser-lrg-bold align-items-center py-4'>Join the Virtu Prep Movement:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        At Virtu Prep, we are not just offering an educational platform; we are building a community committed to lifelong learning and success. Join us in this journey of discovery, growth, and innovation. Welcome to a new era of education. Welcome to Virtu Prep.
                        </p>
                        </div>

                    </div>
                </div>
              
            </div>
            {/* </> */}
        </div>
        // </div>
        // </>


    );
};

export default Aboutus;

